.menu-tab {
  display: flex;
  flex-direction: row;
  height: 100%;
  /* width: calc(100% - 310px);
  position: absolute; */
  margin-bottom: 15px;
  /* min-width: 800px; */
}

.menu-tab-left {
  width: 25%;
  height: 70%;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  /* overflow: auto; */
}

.menu-tab-right {
  width: calc(75% - 10px);
  /* width: 100%; */
  height: 70%;
  background: white;
  margin-left: 10px;
  position: relative;
  overflow: auto;
}

.menu-tab-right-title {
  border-bottom: 1px solid #eee;
  padding: 10px;
}

/* @media screen and (min-width: 1400px) {
  .menu-tab {
    width: calc(100% - 360px) !important;
  }
}


@media screen and (min-width: 1611px) {
    .menu-tab {
      width: calc(100% - 410px) !important;
    }
  }
   */