
.menu-tab-item{
    width: calc(100% - 20px);
    height: 50px;
    margin:10px 10px 0px 10px;
    display:flex;
    border: 1px solid #EEE;
    cursor: pointer;
}
.menu-tab-item:hover {
    border: 1px solid rgba(0, 0, 0, 0.37);
}
.menu-tab-item:hover .menu-tab-item-text{
    /* color:rgba(220, 53, 69, 1) */
}

.menu-tab-item:hover .menu-tab-item-icon{
    /* color:rgba(220, 53, 69, 1); */
    /* background: rgba(220, 53, 69, 1); */
    /* color: white; */
}
.menu-tab-item-icon{
    height: 48px;
    width: 50px;
    background: #EEEEEE;
    color:#808080;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #EEE;
}

.menu-tab-item-text{
    color:#808080;
    margin-left: 10px;
}

.menu-tab-item-text-title{
    margin-top: 7px;
}

.menu-tab-item-text-title.active{
    font-weight: bold;
}


.menu-tab-item-text-subtitle{
    font-size: 9px;
}