.container-edit-field {
  display: flex;
}

.container-edit-field:hover {
  background: #efefef;
}

.edit-icon-contact-details {
  visibility: hidden;
}

.container-edit-field:hover > .edit-icon-contact-details {
  visibility: visible;
}

.container-password-input {
  background: #fff;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border: 0.0625rem solid #d7dae3;
  padding: 0.75rem 0 0 1.25rem;
  color: #6e6e6e;
  border-radius: 1rem;
  width: 100%;
  padding: 0.75rem 0 0 1.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #393939;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d7dae3;
  appearance: none;
  border-radius: 0 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 3.5rem;
  position: relative;
  cursor: text;
}

.container-password-input.focused-input {
  border-color: #dc3545;
}

@media only screen and (max-width: 87.5rem) {
  .container-password-input {
    height: 3rem;
  }
}

.password-input {
  border: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #393939;
}

.cursor-pointer {
  cursor: pointer !important;
}

.password-icon {
  cursor: pointer;
  position: absolute;
  right: 12px;
  color: #393939 !important;
}
