.stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;
  }
  
  .circle {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .circle.active {
    background-color: #dc3545;
  }
  
  .circle.completed {
    background-color: #bd2130;
  }
  
  .circle.disabled {
    background-color: #e0e0e0;
  }
  
  .circle-label {
    color: white;
    font-size: 12px;
    font-weight: bold;
  }
  
  .step-label {
    position: absolute;
    bottom: -20px;
    /* left: -50%; */
    width: 100px;
    text-align: center;
    font-size: 10px;
  }
  
  .line {
    height: 2px;
    position: relative;
    top: -1px;
    z-index: 0;
    background-color: #e0e0e0;
}

.line.active {
    background-color: #dc3545;
    z-index: 1;
    width: 100%;
}

.line.completed {
    background-color: #bd2130;
}
  
  .line.disabled {
    background-color: #e0e0e0;
  }