.container-edit-field {
  display: flex;
}

.container-edit-field:hover {
  background: #efefef;
}

.edit-icon-contact-details {
  visibility: hidden;
}

.container-edit-field:hover > .edit-icon-contact-details {
  visibility: visible;
}

.itp-agent-details__overrides-info {
  display: flex;
  flex-direction: column;
  margin: 30px 18px 4rem;
}

.itp-agent-details__overrides-info:hover .edit-icon-contact-details {
  visibility: visible;
}
