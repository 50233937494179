.dropdown-toggle::after {
  display: none !important;
}

.select-all {
  color: #dc3545;
  text-decoration: none;
}

.select-all:hover {
  text-decoration: underline;
}
