.itp-container-bulk-actions {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #70707029;
  border-radius: 16px;
  opacity: 1;
  margin-left: auto;
  display: inline-block;
}

.itp-bulk-actions {
  display: flex;
  gap: 16px;
}

.itp-label-bulk-actions {
  text-align: left;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  margin: 4px 0 8px 10px;
}

.itp-icon-bulk-action {
  margin-top: 2px;
  margin-bottom: 4px;
}

.itp-bulk-icon-hover {
  visibility: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #70707073;
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  margin-top: 4px;
  z-index: 2;
}

.itp-icon-bulk-action:hover > .itp-bulk-icon-hover {
  visibility: visible;
}

.itp-last-icon-bulk-actions {
  margin-right: 10px;
}

.itp-bulk-icon-name {
  text-align: left;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  margin: 10px;
}
