
@media (min-width: 1400px) {
    .multiSelectITP{
        height:40px !important;
    }
  }

  @media (min-width: 1600px) {
    .multiSelectITP{
        height:45px !important;
    }
  }  

  .__react_component_tooltip.place-top {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .__react_component_tooltip.place-bottom {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }