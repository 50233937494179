.go2791332690{
    color: #dc3545 !important;
    font-size: 18px
}

.step-form-horizontal ol .go3842760039 span{
    border-color: #dc3545 !important;
    background: #dc3545 !important;
    color: white !important;
}

.step-form-horizontal ol .go433304200 span{
    border-color: #dc3545 !important;
    background: #dc3545 !important;
    color: white !important;
}

.go433304200:before {
    background:#dc3545 !important ;
    width: 100% !important;
}

.buttonWizzard{
    background: #ccc !important;
    border-width: 0px !important;
    color: #fff !important;
    font-size: 0.875rem !important;
    padding: 0.625rem 1rem !important;
    margin-right: 5px !important;
}

.buttonWizzard:active{
    background: #ababab !important;
}

.react-select__menu {
    z-index: 9999;
}

.react-select__menu {
    position: fixed; /* O position: absolute; */
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
}

.react-select__menu {
    max-height: 900px; /* Ajusta el tamaño según tus necesidades */
  }
  
  
  