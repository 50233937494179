.position-menu-fixed {
  position: fixed;
  height: 100%;
  z-index: 3;
  overflow: auto;
  bottom: 0;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 5s ease-in-out;
}

.menu-open {
  transform: translateX(0);
}
