.container-label {
  position: relative;
}

.container-label-description {
  position: absolute;
  visibility: hidden;
  background-color: #fff;
  top: -9rem;
  width: 20rem;
  box-shadow: 0px 0px 24px #00000029;
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 0.5rem;
}

.container-label:hover > .container-label-description {
  visibility: visible;
}
