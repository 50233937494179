i.fa {
  width: 30px !important;
}

i.fas {
  width: 30px !important;
}

i.fas.active-list {
  color: #dc3545 !important;
}

.dlabnav .metismenu > li a > i {
  margin-right: 5px !important;
}

.has-arrow.disabled-list {
  background-color: transparent !important;
  color: #737b8b !important;
}

.has-arrow.disabled-list i {
  color: #737b8b !important;
}

.has-arrow.disabled-list > *:nth-child(2) {
  color: #737b8b !important;
}

.has-arrow.disabled-list::after {
  border-top: 0.3125rem solid #c8c8c8 !important;
  border-left: 0.3125rem solid #c8c8c8 !important;
  border-bottom: 0.3125rem solid transparent !important;
  border-right: 0.3125rem solid transparent !important;
  right: 3.5rem !important;
  top: 48% !important;
}

.has-arrow.active-list {
  background-color: rgba(220, 53, 69, 0.1) !important;
  color: #dc3545 !important;
  border-radius: 3.875rem !important;
}
