.dropdown-autocomplete {
  position: relative;
  display: inline-block;
  width: 50%;
}

.dropdown-autocomplete__input {
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  font-size: 14px;
}

.dropdown-autocomplete__list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  max-height: 150px;
  overflow-y: auto;
}

.dropdown-autocomplete__item {
  padding: 6px 10px;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-autocomplete__item:hover {
  background-color: #f5f5f5;
}

.dropdown-autocomplete__item.active {
  background-color: #337ab7;
  color: #fff;
}

.dropdown-autocomplete.show .dropdown-autocomplete__list {
  display: block;
}

ul.options-container.dropdown-autocomplete__options {
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
}
