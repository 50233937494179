.my-select__single-value {
  position: relative;
  right: 0;
  left: 0;
  top: 0.7rem !important;
}

.my-select__value-container {
  height: 3rem;
}

.my-select__input-container.css-1smwn34-Input{
  color: black !important;
}
