.go2791332690{
    color: #dc3545 !important;
    font-size: 18px
}

.step-form-horizontal ol .go3842760039 span{
    border-color: #dc3545 !important;
    background: #dc3545 !important;
    color: white !important;
}

.step-form-horizontal ol .go433304200 span{
    border-color: #dc3545 !important;
    background: #dc3545 !important;
    color: white !important;
}

.go433304200:before {
    background:#dc3545 !important ;
    width: 100% !important;
}

.buttonWizzard{
    background: #ccc;
    border-width: 0px;
    color: #fff;
    font-size: 0.875rem;
    padding: 0.625rem 1rem;
    margin-right: 5px;
}

.buttonWizzard:active{
    background: #ababab;
}